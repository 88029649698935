import React, { useState, useEffect } from "react"

import Layout from "../components/layout"
import NewProjectPreprocessModal from "../components/new-project-preprocess-modal"
import { navigate } from "gatsby";

const NewProjectPage = () => {
  const [images, setImages] = useState([])
  const [openModal, setOpenModal] = useState("modal")
  const [imageURLs, setImageURLs] = useState([])
  const [projectName, setProjectName] = useState("")
  const [projectID, setProjectID] = useState("")
  const [buttonLoading, setButtonLoading] = useState("")
  const [buttonText, setButtonText] = useState("Create Project")

  useEffect(() => {
    if (images.length < 1) return
    const newImageUrls = []
    images.forEach(image => newImageUrls.push(URL.createObjectURL(image)))
    setImageURLs(newImageUrls)
  }, [images])

  function onImageChange(event) {
    setImages([...event.target.files])
  }

  function onProjectNameChange(event) {
    setProjectName(event.target.value)
  }

  function generateProject(e) {
    e.preventDefault()
    buttonText === "Create Project"
      ? setButtonText("Uploading images...")
      : setButtonText("Create Project")
    buttonLoading === "" ? setButtonLoading("loading") : setButtonLoading("")

    console.log("generateProject")
    let finalFormEndpoint = `${process.env.BACKEND_API_URL}/hub/project_create`
    var formData = new FormData()
    console.log(projectName)
    formData.append("data", JSON.stringify({ project_name: projectName }))

    for (const file of images) formData.append("input_images", file)
    console.log("formData")

    fetch(finalFormEndpoint, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Add this line
      },
      body: formData,
    })
      .then(response => {
        if (response.status === 401) {
          // Redirect to login page if Unauthorized
          localStorage.removeItem("token")
          navigate("/login")
          return
        }
        if (response.status !== 200) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then(resJson => {
        console.log(resJson)
        console.log("success")

        setButtonLoading("")
        setButtonText("Create Project")
        setProjectID(resJson["project_id"])
        setOpenModal("modal modal-open")
      })
      .catch(err => {
        console.log(images[0].type)
        console.log("error")
        console.log(err)
      })
  }

  return (
    <Layout>
      <div className="flex flex-wrap">
        <div className="p-5 flex-0">
          <h1 className="text-3xl font-bold">Create a new project</h1>
          <div className="bg-focus">
            <div className="form-control w-full max-w-xs">
              <label className="label">
                <span className="label-text">Select Images:</span>
              </label>
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={onImageChange}
                className="file-input file-input-bordered w-full max-w-xs"
              />
              <label className="label">
                {/* <span className="label-text-alt">Alt label</span>
            <span className="label-text-alt">Alt label</span> */}
              </label>
            </div>
            <div className="form-control w-full max-w-xs">
              <label className="label">
                <span className="label-text">Project name:</span>
              </label>
              <input
                type="text"
                placeholder="Type here"
                className="input input-bordered w-full max-w-xs"
                onChange={onProjectNameChange}
              />
              <label className="label">
                {/* <span className="label-text-alt">Alt label</span> */}
                {/* <span className="label-text-alt">Alt label</span> */}
              </label>
            </div>
            <div>
              <form onSubmit={generateProject}>
                <button
                  className={
                    "btn btn-outline btn-primary btn-md " + buttonLoading
                  }
                >
                  {buttonText}
                </button>
              </form>
              <NewProjectPreprocessModal
                open={openModal}
                projectId={projectID}
                projectName={projectName}
              ></NewProjectPreprocessModal>
            </div>
          </div>
        </div>
        <div className="p-5 flex-1">
          <h1>Info box</h1>
        </div>
      </div>
    </Layout>
  )
}

export default NewProjectPage

export const Head = () => <title>Create new Scan Project</title>
