import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

const NewProjectPreprocessModal = ({ projectId, projectName, open }) => {
  const availableProjectStates = [
    "init",
    "preprocess_bot_connected",
    "preprocess_bot_create_masks",
    "preprocess_bot_create_alphas",
    "preprocess_bot_create_poses",
    "preprocess_bot_success",
    "preprocess_bot_failed",
  ]

  const [checked, setChecked] = useState(true)
  const [projectStatus, setProjectStatus] = useState("0")
  const [projectStatusTaskbot, setProjectStatusTaskbot] =
    useState("step-accent")
  const [projectStatusMasks, setProjectStatusMasks] = useState("")
  const [projectStatusAlphas, setProjectStatusAlphas] = useState("")
  const [projectStatusPoses, setProjectStatusPoses] = useState("")
  const [projectStatusSuccess, setProjectStatusSuccess] = useState("")
  const [projectStatusFailed, setProjectStatusFailed] = useState("")
  const [meshButtonLoading, setMeshButtonLoading] = useState("")

  useEffect(() => {
    console.log("open: " + open)
    if (open === "modal modal-open") {
      const interval = setInterval(() => {
        updateProjectStatus()
      }, 3000)
      return () => clearInterval(interval)
    }
  }, [open])

  useEffect(() => {
    console.log("project status updated to: " + projectStatus)
    if (projectStatus === "preprocess_bot_connected") {
      updateAllProjectStatuses("step-primary", "step-accent", "", "", "", "")
    } else if (projectStatus === "preprocess_bot_create_masks") {
      updateAllProjectStatuses("step-primary", "step-accent", "", "", "", "")
    } else if (projectStatus === "preprocess_bot_create_alphas") {
      updateAllProjectStatuses(
        "step-primary",
        "step-primary",
        "step-accent",
        "",
        "",
        ""
      )
    } else if (projectStatus === "preprocess_bot_create_poses") {
      updateAllProjectStatuses(
        "step-primary",
        "step-primary",
        "step-primary",
        "step-accent",
        "",
        ""
      )
    } else if (projectStatus === "preprocess_bot_success") {
      updateAllProjectStatuses(
        "step-primary",
        "step-primary",
        "step-primary",
        "step-primary",
        "step-primary",
        ""
      )
    } else if (projectStatus === "preprocess_bot_failed") {
      updateAllProjectStatuses(
        "step-primary",
        "step-primary",
        "step-primary",
        "step-primary",
        "",
        "step-failed"
      )
    }
  }, [projectStatus])

  useEffect(() => {
    if (projectStatusSuccess === "step-primary" && checked) {
      navigate("/editor/editor-scan/", {
        state: {
          project_name: projectName,
          project_id: projectId,
        },
      })
    }
  }, [projectStatusSuccess])

  useEffect(() => {
    if (checked) {
      setMeshButtonLoading(" btn-disabled")
    } else {
      setMeshButtonLoading(" btn-outline")
    }
  }, [checked])

  function updateAllProjectStatuses(
    taskbotConnected,
    masksCreated,
    alphasCreated,
    posesCreated,
    success,
    failed
  ) {
    setProjectStatusTaskbot(taskbotConnected)
    setProjectStatusMasks(masksCreated)
    setProjectStatusAlphas(alphasCreated)
    setProjectStatusPoses(posesCreated)
    setProjectStatusSuccess(success)
    setProjectStatusFailed(failed)
  }

  function updateProjectStatus() {
    const token = localStorage.getItem("token")
    let getProjectStatusEndpoint = `${process.env.BACKEND_API_URL}/hub/project_status/${projectId}`
    fetch(getProjectStatusEndpoint, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        if (response.status === 401) {
          // Redirect to login page if Unauthorized
          localStorage.removeItem("token")
          navigate("/login")
          return
        }
        if (response.status !== 200) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then(resJson => {
        if (projectStatus === resJson.project_status) {
          console.log("same status")
        } else {
          setProjectStatus(resJson.project_status)
          // setProjectStatus("preprocess_bot_success")
        }
      })
  }

  return (
    <div className={open}>
      <div className="modal-box relative">
        <label
          htmlFor="my-modal-3"
          className="btn btn-sm btn-circle absolute right-2 top-2"
        >
          ✕
        </label>
        <h1 className="text-lg font-bold">
          Preprocess steps for {projectName}
        </h1>
        {/* <p>Those steps need to finish successfully to start the mesh generation</p> */}
        <div className="divider"></div>

        {/* TODO: create the backend for status updates */}
        <ul className="steps steps-vertical">
          <li data-content="✓" className="step step-primary">
            Upload images
          </li>
          <li
            data-content={projectStatusTaskbot === "step-primary" ? "✓" : "●"}
            className={"step " + projectStatusTaskbot}
          >
            Waiting for a task bot to connect.
          </li>
          <li
            data-content={projectStatusMasks === "step-primary" ? "✓" : "●"}
            className={"step " + projectStatusMasks}
          >
            Create masks from images.
          </li>
          <li
            data-content={projectStatusAlphas === "step-primary" ? "✓" : "●"}
            className={"step " + projectStatusAlphas}
          >
            Remove background from images.
          </li>
          <li
            data-content={projectStatusPoses === "step-primary" ? "✓" : "●"}
            className={"step " + projectStatusPoses}
          >
            Create camera poses.
          </li>
        </ul>

        <br></br>
        <div className="divider"></div>
        {/* <div className="form-control"> */}
        <div className="flex flex-row">
          <div className="flex flex-col">
            <label className="label">
              <input
                type="checkbox"
                defaultChecked={checked}
                className="checkbox checkbox-info"
                onChange={() => setChecked(!checked)}
              />
              <span className="label-text pl-3">
                Automatically start mesh generation
              </span>
            </label>
          </div>
        </div>
        {/* </div> */}

        <button className="btn btn-outline btn-warning ">
          Stop all processes
        </button>

        <button className={"btn btn-primary " + meshButtonLoading}>
          Start mesh generation
        </button>
      </div>
    </div>
  )
}

export default NewProjectPreprocessModal
